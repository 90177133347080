<template>
	<div id="app-election-office">
		<div>
			<back-step
				v-if="false"
				:step-number="7"
			></back-step>

			<app-stepper :step-number="7"></app-stepper>
			<div class="row flex-column align-items-center mainBox">
				<h2 class="mx-md-4 mx-3 mb-1 text-center text-format-1">
					Selecciona la oficina a la que deseas pertenecer
				</h2>
				<div class="offices">
					<div
						class="selectBox d-flex"
						v-for="office in offices"
						:key="office.id"
					>
						<label class="containerBox">
							<input
								data-test-id="same-address-checkbox"
								type="button"
								tabindex="1"
								@click="pickOffice(office)"
								:id="office.id"
							/>
							<span
								v-if="isSelected(office)"
								class="checkmarkBF"
							></span>
							<span
								v-else
								class="checkmarkBF selected"
							></span>
						</label>
						<p class="block m-0 tf text-format-2x">{{ office.id + ' - ' + office.name }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="confirm-button-group">
			<button
				class="button-confirm my-3 marginB mb-sm-1"
				type="submit"
				:disabled="isButtonDisabled"
				data-testid="confirm-button"
				@click.prevent="submit"
			>
				CONTINUAR
			</button>
		</div>
	</div>
</template>

<script>
import AppStepper from '@/components/app-stepper';
import BackStep from '@/components/back-step';

export default {
	name: 'app-election-office',

	components: {
		AppStepper,
		BackStep,
	},

	props: {
		offices: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			selectedOffice: null,
			isButtonDisabled: true,
		};
	},

	methods: {
		pickOffice(office) {
			this.selectedOffice = office;
		},

		isSelected(office) {
			return !(this.selectedOffice && this.selectedOffice.id === office.id);
		},

		submit() {
			this.$store
				.dispatch('onboarding/saveProcess', { officeId: this.selectedOffice.id })
				.then(() => this.$router.push('app-confirmation'));
		},
	},

	watch: {
		selectedOffice: {
			handler(value) {
				if (value) {
					this.isButtonDisabled = false;
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 50px 0;
	gap: 20px;
}

.offices {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: center;
}

.containerBox {
	margin-top: 1px;
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
}
.containerBox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmarkBF {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 21px;
	border: 2px solid #113456;
	border-radius: 40px;
	&.selected {
		background-color: $color-primary;
	}
}

.containerBox input:checked ~ .checkmarkBF:after {
	display: block;
}
.containerBox .checkmarkBF:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 12px;
	border: solid $color-primary;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.selectBox {
	border: 1px solid #113456;
	padding: 15px;
	border-radius: 5px;
	min-width: 350px;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

@media screen and (max-device-width: 480px) {
	.containerBox .checkmark:after {
		left: 5px;
		top: 1px;
		width: 5px;
		height: 11px;
	}
}
</style>
